<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="500px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template> -->

      <v-card>
        <v-card-title>อัปโหลดไฟล์</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <v-form ref="form" v-model="valid">
            <v-file-input
              ref="myFileInput"
              v-model="files"
              counter
              label="ไฟล์ที่ต้องการอัปโหลด"
              multiple
              :rules="[(v) => !!v || 'กรุณาเลือกไฟล์ที่ต้องการอัปโหลด']"
              dense
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2">
                  {{ text }}
                </v-chip>

                <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                  +{{ files.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>
          </v-form>
        </v-card-text>
        <v-card-text>
          <v-list dense nav shaped>
            <!-- <v-subheader inset>Folders</v-subheader> -->

            <v-list-item v-for="item in listuploadfile" :key="item.filename">
              <v-list-item-avatar>
                <v-icon v-text="item.icon[0]" :color="item.icon[1]"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.filename"></v-list-item-title>
                <v-list-item-subtitle v-text="item.status" v-if="item.color === 'red'" style="color: red"> </v-list-item-subtitle>
                <v-list-item-subtitle v-text="item.status" v-else-if="item.color === 'green'" style="color: green">
                </v-list-item-subtitle>
                <v-list-item-subtitle v-text="item.status" v-else> </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <!-- <v-btn icon>
            <v-icon color="grey lighten-1">mdi-information</v-icon>
          </v-btn> -->
                <v-progress-circular
                  :rotate="-90"
                  :size="30"
                  :width="5"
                  :value="item.percent"
                  :color="item.color"
                  :indeterminate="item.Indeterminate"
                ></v-progress-circular>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('close'), cleardata()" :disabled="createprogress">ปิด</v-btn>
          <v-btn color="primary" depressed @click="uploadfiles()" :loading="createprogress" :disabled="createprogress"
            >อัปโหลดไฟล์</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    foldername: { required },
  },
  props: ["show", "parentfolder"],
  data: function() {
    return {
      valid: true,
      files: [],
      foldername: "",
      createprogress: false,
      uploadPercentage: 0,
      uploadProcess: "",
      uploadColor: "light-blue",
      uploadIndeterminate: false,
      listuploadfile: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) return errors;
      !this.$v.foldername.required && errors.push("กรุณากรอกชื่อ โฟลเดอร์");
      return errors;
    },
  },
  methods: {
    async newuploadfiles() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      if (this.$refs.form.validate()) {
        this.listuploadfile = [];
        var i;
        this.createprogress = true;
        for (i = 0; i < this.files.length; i++) {
          this.uploadPercentage = 0;
          this.uploadColor = "light-blue";
          let datafileupload = {};
          let typefile = this.files[i]["name"].split(".");
          let formData = new FormData();
          let filesize = this.files[i]["size"];
          let filename = this.files[i]["name"];
          console.log(this.files);
          formData.append("file", this.files[i]);
          formData.append("size_file", filesize);
          formData.append("folder_id", this.parentfolder);
          formData.append("user_id", "5555");

          datafileupload["filename"] = filename;
          datafileupload["icon"] = this.seticon(typefile);
          datafileupload["status"] = "กำลังอัปโหลดไฟล์";
          datafileupload["message"] = "กำลังอัปโหลดไฟล์";
          datafileupload["color"] = "";
          datafileupload["percent"] = 0;
          datafileupload["Indeterminate"] = false;
          this.listuploadfile.push(datafileupload);
        }
      }
    },
    async uploadfiles() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      if (this.$refs.form.validate()) {
        this.listuploadfile = [];
        var i;
        this.createprogress = true;
        for (i = 0; i < this.files.length; i++) {
          this.uploadPercentage = 0;
          this.uploadColor = "light-blue";
          let datafileupload = {};
          let typefile = this.files[i]["name"].split(".");
          let formData = new FormData();
          let filesize = this.files[i]["size"];
          let filename = this.files[i]["name"];
          console.log(this.files);
          formData.append("file", this.files[i]);
          formData.append("size_file", filesize);
          formData.append("folder_id", this.parentfolder);
          formData.append("user_id", "5555");

          datafileupload["filename"] = filename;
          datafileupload["icon"] = this.seticon(typefile);
          datafileupload["status"] = "กำลังอัปโหลดไฟล์";
          datafileupload["message"] = "กำลังอัปโหลดไฟล์";
          datafileupload["color"] = "";
          datafileupload["percent"] = 0;
          datafileupload["Indeterminate"] = false;
          this.listuploadfile.push(datafileupload);

          let auth = await gbfGenerate.generateToken();
          let objIndex = this.listuploadfile.findIndex((obj) => obj.filename === filename);
          this.axios
            .post("https://etaxgateway.one.th/onebox/api/upload_files", formData, {
              headers: { Authorization: auth.code },
              onUploadProgress: function(progressEvent) {
                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                this.listuploadfile[objIndex]["percent"] = this.uploadPercentage;
                if (this.uploadPercentage >= 100) {
                  this.listuploadfile[objIndex]["Indeterminate"] = true;
                }
              }.bind(this),
            })
            .then((response) => {
              this.listuploadfile[objIndex]["Indeterminate"] = false;
              if (response.data.status === "OK") {
                this.listuploadfile[objIndex]["status"] = "สำเร็จ";
                this.listuploadfile[objIndex]["message"] = "อัปโหลดไฟล์สำเร็จ";
                this.listuploadfile[objIndex]["color"] = "green";
              } else {
                this.listuploadfile[objIndex]["status"] = "ล้มเหลว";
                this.listuploadfile[objIndex]["message"] = "อัปโหลดไฟล์ไม่สำเร็จ";
                this.listuploadfile[objIndex]["color"] = "red";
              }
            })
            .catch((error) => {
              this.listuploadfile[objIndex]["Indeterminate"] = false;
              console.log(error);
              this.listuploadfile[objIndex]["status"] = "ล้มเหลว";
              this.listuploadfile[objIndex]["message"] = "อัปโหลดไฟล์ไม่สำเร็จ";
              this.listuploadfile[objIndex]["color"] = "red";
            });
        }
        this.createprogress = false;
        this.files = [];
        this.$refs.form.reset();
      }
    },
    seticon(parameter) {
      console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-question", "blue-grey"];
      }
      return dataicon;
    },
    cleardata() {
      this.files = [];
      this.createprogress = false;
      this.uploadPercentage = 0;
      this.listuploadfile = [];
    },
  },
};
</script>
